details ::-webkit-details-marker {
  display: none;
}
details ::-moz-list-bullet {
  font-size: 0;
}
.siderbar{
  width: 32%;
  /* min-width: 30%; */
}
.post_catalog{
  /* position: sticky;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid #ccc;
  max-height: 500px;
  overflow-y: scroll; */
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 11;
  margin-right: 10%;
  max-height: 800px;
  border-top: 3px double #ccc;
  border-bottom: 3px double #ccc;
  white-space: nowrap;
  overflow: auto;
}
.main_content{
  border-top: 3px double #ccc;
  border-bottom: 3px double #ccc;
  flex: 1;
  width: 30%;
}
.post_summary{
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 11;
}
summary{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
summary:focus{
  outline:none;
}
.page-post {
  font-size: 16px;
  line-height: 2;
  word-break: break-all;
  display: flex;
}
.page-post .title {
  font-size: 20px;
  font-weight: bold;
  color: #666666;
  padding-top: 12px;
}
.page-post .subtitle {
  font-size: 14px;
  color: #999999;
}
.page-post .post > * {
  margin: 1px 0;
}
.page-post .post > iframe {
  margin: 0;
  width: 100%;
}
.page-post .post h1,
.page-post .post h2 {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  /* color: #32cd32; */
  /* background-color: #f0fff0; */
  margin:5px 0;
  /*重置计数器h3counter的值*/
  counter-reset: h3counter;
  /*计数器h2counter的值增加1*/
  counter-increment: h2counter;
}
.page-post .post h1::before,
.page-post .post h2::before {
  /* content: '>';
  top: 0;
  font-weight: bold;
  padding-right: 6px;
  color: #32cd32; */
  /*内容显示为：计数器h2counter的值转换为中文数字格式+顿号，格式：一、*/
  content: counter(h2counter) ". ";
}
.page-post h3 {
  font-size: 18px;
  font-weight: bold;
  /* color:#ff1493; */
  /* background-color: #fff0f5; */
  margin:5px 0;
  counter-reset: h4counter;
  counter-increment: h3counter;
}
.page-post h4 {
  font-size: 16px;
  font-weight: bold;
  /* color:#6495ed; */
  counter-increment: h4counter;
}
.page-post h5 {
  font-size: 15px;
  font-weight: bold;
  color:#8a2be2;
}
.page-post h6 {
  font-size: 15px;
  font-weight: bold;
  color:#000;
}

.page-post .post h3::before{
  /* content: '~';
  top: 0;
  font-weight: bold;
  padding-right: 6px;
  color: #ff1493; */
  content: counter(h2counter) "." counter(h3counter) ". ";
}
.page-post .post h4::before{
  /* content: '##';
  top: 0;
  font-weight: bold;
  padding-right: 6px;
  color: #6495ed; */
  content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ". ";
}
.page-post .post h5::before{
  content: '###';
  top: 0;
  font-weight: bold;
  padding-right: 6px;
  color: #8a2be2;
}
.page-post .post h6::before{
  content: '####';
  top: 0;
  font-weight: bold;
  padding-right: 6px;
  color: #000;
}
.page-post blockquote {
  /* text-indent: 2em; */
  background-color: floralwhite;
}

.page-post a {
  color: #000000;
  margin: 0 2px;
  border-bottom: 1px solid #000000;
}
.page-post strong,
.page-post b {
  color: #000000;
}
.page-post .post ul {
  margin-left: 16px;
}
.page-post .post ol {
  margin-left: 28px;
}
.page-post ul li {
  list-style: disc;
}
.page-post ol li {
  list-style: decimal;
}
.my_toc ol li{
  list-style: none;
}
.my_toc ol {
    padding: 0 0 0 16px;
    margin: 0;
    list-style: none;
    counter-reset: a;
}
.my_toc li:before {
    counter-increment: a;
    content: counters(a,".")". ";
}
.page-post img {
  display: block;
  text-align:center;
  max-width: 70%;
  margin: 5px auto;
  height: auto;
  box-shadow: 0 0 2px #e0e0e0;
}
.page-post img[alt='line'] {
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  box-shadow: none;
  margin: 0 4px;
  line-height: 1;
}
.img_des{
  text-align: center;
  color: #999999;
}
.page-post code {
  background-color: gainsboro;
  word-break: break-all;
  padding: 0 4px;
  margin: 0 2px;
  /*border-radius: 4px;*/
  color: #4d4d4c;
}
.page-post pre {
  font-size: 0;
  padding: 10px;
  overflow: auto;
  /* border-radius: 2px; */
  /* background-color: #f7f7f7; */
  /* max-height: 610px; */
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  margin:5px 0;
  background-image: linear-gradient(90deg,rgba(50,0,0,.05) 3%,transparent 0),linear-gradient(1turn,rgba(50,0,0,.05) 3%,transparent 0);
  background-size: 20px 20px;
  background-position: 50%;
}
.page-post pre code {
  background-color: transparent;
  font-size: 14px;
  padding: 0;
  margin: 0;
  line-height: 1.6;
}
.page-post hr {
  border: none;
  height: 1px;
  background-color: #f5f5f5;
}
.page-post table {
  min-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #dddddd;
}
.page-post tr {
  /* border-bottom: 1px solid #dddddd; */
  border-bottom: border: 1px rgba(50,0,0,.05) solid;;
}
.page-post th {
  font-weight: bold;
}
.page-post th,
.page-post td {
  padding: 5px 10px;
  text-align: left;
}
.page-post table tr:nth-child(even) {
  background-color: #f9f9f9;
}
.page-post .table-container {
  overflow: auto;
  margin: 14px 0;
  /* border: 1px solid #dddddd; */
  border: 1px rgba(50,0,0,.05) solid;
}
.page-post .table-container table {
  margin: 0;
  border: 0;
}
.page-post .table-container table tbody tr:last-child {
  border-bottom: 0;
}
@media screen and (max-width: 800px) {
  .siderbar{
    display: none;
  }
  .page-post {
    font-size: 13px;
  }
  .page-post pre code {
    font-size: 12px;
  }
  .page-post .post > * {
    margin: 2px 0;
  }
  .page-post img {
	max-width: 100%;
  }
  .post_catalog{
    max-height: 300px;
  }
}
